import React from 'react';

const ServiceProviderPlans = () => {
  return (
    <section className="plan-area" data-aos="fade-up">
      <div className="container">
        <div className="row plan-inner d-flex flex-row col-xs-px-5">
          <div className="col-lg-4 service-hero-cnt">
            <div className="shc-title">
              <h2>
                advantages of our <span className="color-1">pre-launch</span> offer...
              </h2>
            </div>
            <div className="shc-checkbox-area">
              <form action="">
                <div className="shc-checkbox d-flex align-items-start">
                  <input type="checkbox" id="showcase" name="showcase" value="showcase" checked className="mt-2" />
                  <label htmlFor="showcase">
                    competitive advantage - <span className="color-1">start early building a profile & collecting reviews</span>
                  </label>
                </div>
                <div className="shc-checkbox">
                  <input type="checkbox" id="showcase" name="showcase" value="showcase" checked />
                  <label htmlFor="showcase">
                    early bird <span className="color-1">discount</span>
                  </label>
                </div>
                <div className="shc-checkbox">
                  <input type="checkbox" id="showcase" name="showcase" value="showcase" checked />
                  <label htmlFor="showcase">
                    discounted pricing for <span className="color-1">upto 3 years</span>
                  </label>
                </div>
                <div className="shc-checkbox">
                  <input type="checkbox" id="showcase" name="showcase" value="showcase" checked />
                  <label htmlFor="showcase">
                    <span className="color-1">4x income guaranteed</span> or full refund
                  </label>
                </div>
                <div className="shc-checkbox">
                  <input type="checkbox" id="showcase" name="showcase" value="showcase" checked />
                  <label htmlFor="showcase">
                    {' '}
                    lower competition - <span className="color-1">higher ROI</span>
                  </label>
                </div>
              </form>
            </div>
          </div>

          <div className="col-lg-7 plan-cnt-box text-center plan-cnt-box1 position-relative">
            <img src="images/limited-time.png" alt="" className="img-fluid limited-time-img" />
            <div className="plan-icon text-start">
              <img src="images/limited-offer.svg" alt="" className="img-fluid" />
            </div>
            <div className="row plan-row d-flex align-items-center mt-3 mx-1">
              <div className="col-4">
                <h2>advantage</h2>
              </div>
              <div className="col-4 plan-col2 d-flex align-items-center justify-content-center">
                <div>
                  <h2 className="color-1">monthly</h2>
                  <span>per city</span>
                  <h2 className="color-1 plan-price1 mt-3">$29.99</h2>
                  <div className="position-relative">
                    <p className="plan-price2">$49.99</p>
                    <img src="images/line.svg" alt="" className="img-fluid line-through" />
                  </div>
                </div>
              </div>
              <div className="col-4 plan-col3 d-flex align-items-center justify-content-center">
                <div>
                  <h2 className="color-3">annually</h2>
                  <span>per city</span>
                  <h2 className="color-1 plan-price1 mt-3">$299.99</h2>
                  <div className="position-relative">
                    <p className="plan-price2">$499.99</p>
                    <img src="images/line.svg" alt="" className="img-fluid line-through2" />
                  </div>
                </div>
              </div>
            </div>
            <div className="row plan-row mx-1 mt-1">
              <div className="col-4 plan-col-m1">
                <div className="plan-col-m1 w-100 h-100 d-flex align-items-center justify-content-center">
                  <h2>premium</h2>
                </div>
              </div>
              <div className="col-4 plan-col-middle d-flex align-items-center justify-content-center">
                <div>
                  <h2 className="color-1 plan-price1 mt-3">$99.99</h2>
                  <div className="position-relative">
                    <p className="plan-price2">$149.99</p>
                    <img src="images/line.svg" alt="" className="img-fluid line-through3" />
                  </div>
                </div>
              </div>
              <div className="col-4 plan-col-m3 d-flex align-items-center justify-content-center">
                <div>
                  <h2 className="color-1 plan-price1 mt-3">$999.99</h2>
                  <div className="position-relative">
                    <p className="plan-price2">$1499.99</p>
                    <img src="images/line.svg" alt="" className="img-fluid line-through3" />
                  </div>
                </div>
              </div>
            </div>
            <div className="row plan-row mx-1 mt-1">
              <div className="col-4 plan-col-last ">
                <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                  <h2>enterprise</h2>
                </div>
              </div>
              <div className="col-4 plan-last-middle d-flex align-items-center justify-content-center">
                <div>
                  <p className="plan-last">multiple products</p>
                  <p className="plan-last">multiple users</p>
                  <p className="plan-last">multiple cities</p>
                  <p className="plan-last">multiple provinces</p>
                </div>
              </div>
              <div className="col-4 plan-last-end d-flex align-items-center justify-content-center">
                <div>
                  <div className="bdc-btn contact-sales-btn pe-0">
                    <a target="_blank" href="https://calendly.com/ohmer-sales" rel="noreferrer">
                      contact sales
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceProviderPlans;
