import React from 'react';

const HeroArea = () => {
  return (
    <div className="hero-area service-hero" data-aos="fade-up">
      <div className="container">
        <div className="hero-inner">
          <div className="row">
            <div className="col-lg-3 col-md-3">
              <div className="banner-right" data-aos="fade-up">
                <img src="images/services-hero-1.png" alt="" className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-9 col-md-6">
              <div className="service-hero-cnt" data-aos="fade-up">
                <div className="shc-title">
                  <h2>
                    your business development on <span className="color-1">auto-pilot</span>
                  </h2>
                </div>
                <div className="banner-right-sm text-center" data-aos="fade-up">
                  <img src="images/services-hero-1.png" alt="" className="img-fluid" />
                </div>
                <div className="shc-checkbox-area" data-aos="fade-up">
                  <form action="">
                    <div className="shc-checkbox">
                      <input type="checkbox" id="showcase" name="showcase" value="showcase" checked />
                      <label htmlFor="showcase">
                        effectively <span className="color-1">showcase</span> your products and services
                      </label>
                    </div>
                    <div className="shc-checkbox">
                      <input type="checkbox" id="showcase" name="showcase" value="showcase" checked />
                      <label htmlFor="showcase">
                        generate quality leads through <span className="color-1">verified ratings</span> & reviews
                      </label>
                    </div>
                    <div className="shc-checkbox">
                      <input type="checkbox" id="showcase" name="showcase" value="showcase" checked />
                      <label htmlFor="showcase">
                        instant <span className="color-1">alerts and chats</span> with potential customers
                      </label>
                    </div>
                    <div className="shc-checkbox">
                      <input type="checkbox" id="showcase" name="showcase" value="showcase" checked />
                      <label htmlFor="showcase">
                        easy to use <span className="color-1">lead management portal</span> on your phone
                      </label>
                    </div>
                    <div className="shc-checkbox">
                      <input type="checkbox" id="showcase" name="showcase" value="showcase" checked />
                      <label htmlFor="showcase">
                        {' '}
                        <span className="color-1">o</span>hmer <span className="color-1">certified badge</span> on meeting minimum
                        service level agreement
                      </label>
                    </div>
                    <div className="shc-checkbox">
                      <input type="checkbox" id="showcase" name="showcase" value="showcase" checked />
                      <label htmlFor="showcase">
                        book appointments and <span className="color-1">calendar sync</span>
                      </label>
                    </div>
                    <div className="shc-checkbox">
                      <input type="checkbox" id="showcase" name="showcase" value="showcase" checked />
                      <label htmlFor="showcase">
                        <span className="color-3">premium package:</span> featured on <span className="color-1">top</span> in
                        marketplace
                      </label>
                    </div>
                  </form>
                </div>
                <div className="banner-right-sm text-center banner-right-sm2" data-aos="fade-up">
                  <img src="images/services-hero-2.png" alt="" className="img-fluid" />
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3">
              <div className="banner-right-s" data-aos="fade-up">
                <img src="images/services-hero-2.png" alt="" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroArea;
