import BaseApi from './BaseApi';
import axios from 'axios';
class UsersApi {
  static async register(params) {
    const axiosParams = BaseApi.getParams('POST', 'promotional/users/register');
    axiosParams.data = JSON.stringify(params);
    const response = await axios(axiosParams);
    if (response.status !== 200) {
      throw response;
    }
    return response.data;
  }

  static async investorRegister(params) {
    const axiosParams = BaseApi.getParams('POST', 'promotional/investors/register');
    axiosParams.data = JSON.stringify(params);
    const response = await axios(axiosParams);
    if (response.status !== 200) {
      throw response;
    }
    return response.data;
  }

  static async registerServiceProvider(params) {
    const axiosParams = BaseApi.getParams('POST', 'promotional/serviceProvider/register');
    axiosParams.data = JSON.stringify(params);
    const response = await axios(axiosParams);
    if (response.status !== 200) {
      throw response;
    }
    return response.data;
  }
}

export default UsersApi;
