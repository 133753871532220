import React, {useState, useCallback} from 'react';
import UsersApi from '../../api/UsersApi';
import {isValidEmail} from '../../utils/Global';

const HeroArea = () => {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');

  const handleSubmit = useCallback(() => {
    if (fullName === '' || email === '') {
      alert('Please enter your name and email.');
      return;
    }
    if (!isValidEmail(email)) {
      alert('Please enter a valid email.');
      return;
    }
    const params = {
      fullName,
      email,
    };
    UsersApi.investorRegister(params)
      .then((response) => {
        if (response?.success) {
          alert(response?.message);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          alert(error.response.data.message);
        }
        console.log('ERROR:: ', error);
      });
  }, [fullName, email]);
  return (
    <div className="hero-area service-hero invest-hero" data-aos="fade-up">
      <div className="container">
        <div className="hero-inner">
          <div className="row">
            <div className="col-lg-8 col-md-6">
              <div className="invest-hero-left" data-aos="fade-up">
                <div className="invest-hero-title">
                  <h2>
                    ideal investor for <span>o</span>hmer would be someone with:
                  </h2>
                </div>
                <div className="invest-hero-right-sm" data-aos="fade-up">
                  <img src="images/investors.png" alt="" className="img-fluid" />
                </div>
                <div className="shc-checkbox-area" data-aos="fade-up">
                  <form action="">
                    <div className="shc-checkbox">
                      <input type="checkbox" id="showcase02" name="showcase02" value="showcase02" checked />
                      <label htmlFor="showcase02">
                        real estate <span className="color-1">industry expertise</span>
                      </label>
                    </div>
                    <div className="shc-checkbox">
                      <input type="checkbox" id="showcase03" name="showcase03" value="showcase03" checked />
                      <label htmlFor="showcase03">
                        proven <span className="color-1">track record</span> with startups
                      </label>
                    </div>
                    <div className="shc-checkbox">
                      <input type="checkbox" id="showcase03" name="showcase03" value="showcase03" checked />
                      <label htmlFor="showcase03">
                        solid network across <span className="color-1">finance and home service</span> industry
                      </label>
                    </div>
                    <div className="shc-checkbox">
                      <input type="checkbox" id="showcase04" name="showcase04" value="showcase04" checked />
                      <label htmlFor="showcase04">
                        {' '}
                        <span className="color-1">functional expertise</span> willing to take an advisory role
                      </label>
                    </div>
                  </form>
                </div>
                <div className="invest-hero-title" data-aos="fade-up">
                  <h2>
                    crowdfunding / <span className="color-3">sweat equity</span> opportunities also available for:{' '}
                  </h2>
                </div>
                <div className="shc-checkbox-area" data-aos="fade-up">
                  <form action="">
                    <div className="shc-checkbox">
                      <input type="checkbox" id="showcase05" name="showcase05" value="showcase05" checked />
                      <label htmlFor="showcase05">
                        real estate / corporate <span className="color-1">lawyer</span>
                      </label>
                    </div>
                    <div className="shc-checkbox">
                      <input type="checkbox" id="showcase06" name="showcase06" value="showcase06" checked />
                      <label htmlFor="showcase06">
                        chartered / chartered professional <span className="color-1">accountant</span>
                      </label>
                    </div>
                  </form>
                </div>
                <div className="invest-form">
                  <div className="reg-form">
                    <form>
                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <div className="reg-input">
                            <input
                              type="text"
                              placeholder="name"
                              value={fullName}
                              style={{textTransform: 'capitalize'}}
                              onChange={(e) => setFullName(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="reg-input">
                            <input
                              type="email"
                              placeholder="email address"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-2">
                          <div className="reg-submit" onClick={handleSubmit}>
                            submit
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="invest-hero-right" data-aos="fade-up">
                <img src="images/investors.png" alt="" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroArea;
