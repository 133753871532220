import React, {useState, useEffect, useCallback} from 'react';
import OnBoardingCarousel from './OnBoardingCarousel';
import UsersApi from '../../api/UsersApi';
import {isValidEmail} from '../../utils/Global';

const HeroArea = () => {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');

  // Define target date for countdown
  const targetDate = new Date('2024-11-04T00:00:00'); // Set your target date here
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const distance = targetDate - now;

      if (distance > 0) {
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));

        setTimeLeft({days, hours, minutes});
      } else {
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [targetDate]);

  const handleSubmit = useCallback(() => {
    if (fullName === '' || email === '') {
      alert('Please enter your name and email.');
      return;
    }
    if (!isValidEmail(email)) {
      alert('Please enter a valid email.');
      return;
    }
    const params = {
      fullName,
      email,
    };
    UsersApi.register(params)
      .then((response) => {
        if (response?.success) {
          alert(response?.message);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          alert(error.response.data.message);
        }
        console.log('ERROR:: ', error);
      });
  }, [fullName, email]);

  return (
    <div className="hero-area">
      <div className="container">
        <div className="hero-inner index-hero-inner">
          <div className="row">
            <div className="col-lg-8 col-md-8">
              <div className="hero-left" data-aos="fade-up">
                <div className="hero-title" data-aos="fade-up">
                  <h2>
                    <span className="color-1">o</span>
                    <span className="color-2">hmer</span> is a personal{' '}
                    <span className="color-1">real estate monitoring app</span> that promises to keep you{' '}
                    <span className="color-3">informed</span> and <span className="color-3">in control</span> of your real estate
                    assets.{' '}
                  </h2>
                </div>
                <div className="countdown-area" data-aos="fade-up">
                  <div className="countdown-title">
                    <h3>countdown has begun...</h3>
                  </div>
                  <div className="countdown-inner" data-aos="fade-up">
                    <div className="countdown-box">
                      <h3 className="timer-days">{timeLeft.days}</h3>
                      <h4>days</h4>
                    </div>
                    <div className="count-dot">
                      <img src="images/count-dot.svg" alt="" className="img-fluid" />
                    </div>
                    <div className="countdown-box">
                      <h3 className="timer-hours">{timeLeft.hours}</h3>
                      <h4>hours</h4>
                    </div>
                    <div className="count-dot">
                      <img src="images/count-dot.svg" alt="" className="img-fluid" />
                    </div>
                    <div className="countdown-box">
                      <h3 className="timer-minutes">{timeLeft.minutes}</h3>
                      <h4>mins</h4>
                    </div>
                  </div>
                </div>
                <div className="regnow-area" data-aos="fade-up">
                  <div className="regnow-title">
                    <p>
                      <span>register</span> now for promotional offers if you are a tenant, a home buyer, a home owner, a
                      landlord, a real estate service provider or a home service provider.
                    </p>
                  </div>
                  <div className="reg-form">
                    <form>
                      <div className="row">
                        <div className="col-lg-5 col-md-5">
                          <div className="reg-input">
                            <input
                              type="text"
                              placeholder="name"
                              value={fullName}
                              style={{textTransform: 'capitalize'}}
                              onChange={(e) => setFullName(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-5 col-md-5">
                          <div className="reg-input">
                            <input
                              type="email"
                              placeholder="email address"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-2">
                          <div className="reg-submit" onClick={handleSubmit}>
                            submit
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="banner-right">
                <div className="main-content">
                  <OnBoardingCarousel />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroArea;
