import React from 'react';
import {Link, useLocation} from 'react-router-dom';

const Header = (props) => {
  const {children} = props;
  const location = useLocation();

  return (
    <header className="header-area">
      <div className="container">
        <div className="header-inner">
          <div className="nav-items">
            <ul>
              <li>
                <Link to="/" className={`${location.pathname === '/' && 'active-nav'}`}>
                  Home
                </Link>
              </li>
              <li>
                <Link to="/service-provider" className={`${location.pathname === '/service-provider' && 'active-nav'}`}>
                  service provider
                </Link>
              </li>
              <li>
                <Link to="/invest" className={`${location.pathname === '/invest' && 'active-nav'}`}>
                  invest
                </Link>
              </li>
              {/* <li>
                <Link to="/blogs" className={`${location.pathname === '/blogs' && 'active-nav'}`}>
                  blogs
                </Link>
              </li> */}
            </ul>
          </div>
          <div className="logo">
            <Link to="/">
              <img src="images/logo.svg" alt="" />
            </Link>
          </div>
          <div
            className="menu-bar"
            data-bs-toggle="offcanvas"
            href="#offcanvasExample"
            role="button"
            aria-controls="offcanvasExample">
            <a href="#">
              <i className="fa-sharp fa-solid fa-bars"></i>
            </a>
          </div>
        </div>
      </div>
      {/* <!-- OFFCANVAS START --> */}
      <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasExampleLabel">
            <div className="logo">
              <Link to="/">
                <img src="images/logo.svg" alt="" />
              </Link>
            </div>
          </h5>
          <a href="#" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></a>
        </div>
        <div className="offcanvas-body">
          <div className="nav-items">
            <ul>
              <li>
                <Link to="/" data-bs-dismiss="offcanvas" className={`${location.pathname === '/' && 'active-nav'}`}>
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="/service-provider"
                  data-bs-dismiss="offcanvas"
                  className={`${location.pathname === '/service-provider' && 'active-nav'}`}>
                  service provider
                </Link>
              </li>
              <li>
                <Link to="/invest" data-bs-dismiss="offcanvas" className={`${location.pathname === '/invest' && 'active-nav'}`}>
                  invest
                </Link>
              </li>
              {/* <li>
                <Link to="/blogs" className={`${location.pathname === '/blogs' && 'active-nav'}`}>
                  blogs
                </Link>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
      {/* <!-- OFFCANVAS END --> */}

      {/* <!-- HERO AREA START --> */}
      {children}
      {/* <!-- HERO AREA END --> */}
    </header>
  );
};

export default Header;
