import React, {useEffect} from 'react';
import './styles/style.css';
import './styles/responsive.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Home from './pages/Home/Home';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ServiceProvider from './pages/ServiceProvider/ServiceProvider';
import Invest from './pages/Invest/Invest';
import Blogs from './pages/Blogs/Blogs';
import ReactGA from 'react-ga4';

const App = () => {
  useEffect(() => {
    ReactGA.initialize('G-YNK5TY600R');
    AOS.init({
      duration: 1900,
    });
  }, []);

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/service-provider" element={<ServiceProvider />} />
          <Route path="/invest" element={<Invest />} />
          <Route path="/blogs" element={<Blogs />} />
        </Routes>
      </Router>
      {/* <!-- back to top --> */}
      <div className="progress-wrap">
        <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
          <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
        </svg>
      </div>
    </div>
  );
};

export default App;
