import React, {useEffect} from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import HeroArea from './HeroArea';
import ReactGA from 'react-ga4';
import ServiceProviderPlans from './ServiceProviderPlans';

const ServiceProvider = () => {
  useEffect(() => {
    ReactGA.send({hitType: 'pageview', page: '/service-provider'});
  }, []);

  return (
    <div>
      <Header>
        <HeroArea />
      </Header>
      <section className="right-plan-title" data-aos="fade-up">
        <div className="container">
          <div className="plan-title">
            <h2>
              find the <span className="color-1">right plan</span> for your business
            </h2>
          </div>
        </div>
      </section>

      <ServiceProviderPlans />

      <section className="bookdemo-area" data-aos="fade-up">
        <div className="container">
          <div className="bookdemo-cnt">
            <div className="bdc-left">
              <h3>
                still wondering if <span className="color-b">o</span>hmer is <span className="color-1">right</span> for you?
              </h3>
              <p>book a demo with one of our customer support specialists to see ohmer in action</p>
            </div>
            <div className="bdc-btn">
              <a target="_blank" href="https://calendly.com/ohmer-sales" rel="noreferrer">
                book a demo
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="right-plan-title" data-aos="fade-up">
        <div className="container">
          <div className="plan-title">
            <h2>
              choose a city, category and <span className="color-1">reserve</span> your spot
            </h2>
          </div>
        </div>
      </section>

      <section className="choose-area pt-0" data-aos="fade-up">
        <div className="container">
          <div className="choose-inner">
            <div className="row align-items-center">
              <div className="col-lg-4">
                <div className="choose-left">
                  <img src="images/5.png" alt="" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-8">
                <div className="choose-right">
                  <stripe-pricing-table
                    pricing-table-id="prctbl_1Ppa3lDQvVdm5eQ9tI8ralCy"
                    publishable-key="pk_live_51O5XN0DQvVdm5eQ9CJAUqyYm2zUFgzFZyBQStHWKK6HqXIFBfbJI2Ek53LOrDDAcqw3NF5GgCGeOpdjBWMZk4Slx00rxdsnasG"></stripe-pricing-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default ServiceProvider;
