import React, {useEffect} from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import HeroArea from './HeroArea';
import ReactGA from 'react-ga4';

const Home = () => {
  useEffect(() => {
    ReactGA.send({hitType: 'pageview', page: '/'});
  }, []);

  return (
    <div>
      <Header>
        <HeroArea />
      </Header>
      <section className="home-ownership-area" data-aos="fade-up">
        <div className="container">
          <div className="home-ownership-title text-center" data-aos="fade-up">
            <h2>
              home ownership & maintenance <span className="color-1">reborn.</span> <span className="color-3">redefined.</span>
            </h2>
          </div>
          <div className="happy-img-sm" data-aos="fade-up">
            <img src="images/HappyFamily01 1.png" alt="" className="img-fluid" />
          </div>
          <div className="home-ownership-inner" data-aos="fade-up">
            <div className="home-ownership-box" data-aos="fade-up">
              <p>
                monitor your <span className="color-3">real estate health</span> <br />& stay on top of your cash flow
              </p>
            </div>
            <div className="home-ownership-box home-ownership-box2" data-aos="fade-up">
              <p>
                experience the <span className="color-1">power</span> of real estate numbers like never before
              </p>
            </div>
            <div className="home-ownership-box home-ownership-box3" data-aos="fade-up">
              <p>
                hire quality professionals
                <br /> from marketplace with
                <br /> <span className="color-1">verified ratings</span> & reviews
              </p>
            </div>
            <div className="home-ownership-box home-ownership-box4" data-aos="fade-up">
              <p>
                simplified digital
                <br /> relationship
                <br />
                between a landlord
                <br /> and a tenant
              </p>
            </div>
            <div className="home-ownership-box home-ownership-box5" data-aos="fade-up">
              <p>
                passively build{' '}
                <span className="color-3">
                  home life
                  <br /> chart
                </span>{' '}
                with who
                <br /> renovated what when
                <br /> and for how much
              </p>
            </div>
            <div className="home-ownership-box home-ownership-box6 text-end" data-aos="fade-up">
              <p>
                instant market updates
                <br /> so never under sell and
                <br /> never pay more
              </p>
            </div>
            <div className="happy-img">
              <img src="images/HappyFamily01 1.png" alt="" className="img-fluid" />
            </div>
          </div>
        </div>
        <Footer />
      </section>
    </div>
  );
};

export default Home;
