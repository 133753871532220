import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const OnBoardingCarousel = () => {
  return (
    <OwlCarousel
      id="owl-csel1"
      className="owl-carousel owl-theme"
      loop
      autoplay
      startPosition={0}
      items={1}
      margin={15}
      animate="fadeIn"
      animateOut="fadeOut"
      dots
      autoplayTimeout={3000}>
      <div className="slider-img item">
        <div className="couple-img">
          <img src="images/couple.png" alt="" className="img-fluid" />
        </div>
        <div className="phone-img">
          <img src="images/IntroScreen01.png" alt="" className="img-fluid" />
        </div>
      </div>
      <div className="slider-img item">
        <div className="couple-img">
          <img src="images/couple.png" alt="" className="img-fluid" />
        </div>
        <div className="phone-img">
          <img src="images/IntroScreen02.png" alt="" className="img-fluid" />
        </div>
      </div>
      <div className="slider-img item">
        <div className="couple-img">
          <img src="images/couple.png" alt="" className="img-fluid" />
        </div>
        <div className="phone-img">
          <img src="images/IntroScreen03.png" alt="" className="img-fluid" />
        </div>
      </div>
      <div className="slider-img item">
        <div className="couple-img">
          <img src="images/couple.png" alt="" className="img-fluid" />
        </div>
        <div className="phone-img">
          <img src="images/IntroScreen04.png" alt="" className="img-fluid" />
        </div>
      </div>
    </OwlCarousel>
  );
};

export default OnBoardingCarousel;
