import React from 'react';

const HeroArea = () => {
  return (
    <div className="hero-area blogs-hero" data-aos="fade-up">
      <div className="container">
        <div className="blog-hero-title" data-aos="fade-up">
          <h2>
            {' '}
            <span className="color-1">resources</span> and <span className="color-3">news</span> from our team...
          </h2>
          <p>
            Page <span>1</span> of 4
          </p>
        </div>
        <div className="blogs-wrapper" data-aos="fade-up">
          <div className="blogs-content" data-aos="fade-up">
            <div className="blog-img">
              <a href="#">
                <img src="images/blog1 Rent Reporting.jpg" alt="" className="img-fluid" />
              </a>
            </div>
            <div className="blog-cnt-title">
              <h2>
                <a href="#">rent collection and credit bureau reporting</a>
              </h2>
              <p>
                Report Rent Payments, Prevent
                <br /> Losses And Reward Good Tenants...
              </p>
              <p>
                <span>22nd oct 2023 -</span> by team ohmer
              </p>
            </div>
          </div>
          <div className="blogs-content blogs-content2" data-aos="fade-up">
            <div className="blog-img">
              <a href="#">
                <img src="images/blog1 monitor real estate.jpg" alt="" className="img-fluid" />
              </a>
            </div>
            <div className="blog-cnt-title">
              <h2>
                <a href="#">rent collection and credit bureau reporting</a>
              </h2>
              <p>
                Report Rent Payments, Prevent
                <br /> Losses And Reward Good Tenants...
              </p>
              <p>
                <span>22nd oct 2023 -</span> by team ohmer
              </p>
            </div>
          </div>
          <div className="blogs-content" data-aos="fade-up">
            <div className="blog-img">
              <a href="#">
                <img src="images/blog1 Rent Reporting.jpg" alt="" className="img-fluid" />
              </a>
            </div>
            <div className="blog-cnt-title">
              <h2>
                <a href="#">rent collection and credit bureau reporting</a>
              </h2>
              <p>
                Report Rent Payments, Prevent
                <br /> Losses And Reward Good Tenants...
              </p>
              <p>
                <span>22nd oct 2023 -</span> by team ohmer
              </p>
            </div>
          </div>
          <div className="blogs-content blogs-content2" data-aos="fade-up">
            <div className="blog-img">
              <a href="#">
                <img src="images/blog1 monitor real estate.jpg" alt="" className="img-fluid" />
              </a>
            </div>
            <div className="blog-cnt-title">
              <h2>
                <a href="#">rent collection and credit bureau reporting</a>
              </h2>
              <p>
                Report Rent Payments, Prevent
                <br /> Losses And Reward Good Tenants...
              </p>
              <p>
                <span>22nd oct 2023 -</span> by team ohmer
              </p>
            </div>
          </div>
          <div className="blogs-content" data-aos="fade-up">
            <div className="blog-img">
              <a href="#">
                <img src="images/blog1 Rent Reporting.jpg" alt="" className="img-fluid" />
              </a>
            </div>
            <div className="blog-cnt-title">
              <h2>
                <a href="#">rent collection and credit bureau reporting</a>
              </h2>
              <p>
                Report Rent Payments, Prevent
                <br /> Losses And Reward Good Tenants...
              </p>
              <p>
                <span>22nd oct 2023 -</span> by team ohmer
              </p>
            </div>
          </div>
          <div className="blogs-content" data-aos="fade-up">
            <div className="blog-img">
              <a href="#">
                <img src="images/blog1 monitor real estate.jpg" alt="" className="img-fluid" />
              </a>
            </div>
            <div className="blog-cnt-title">
              <h2>
                <a href="#">rent collection and credit bureau reporting</a>
              </h2>
              <p>
                Report Rent Payments, Prevent
                <br /> Losses And Reward Good Tenants...
              </p>
              <p>
                <span>22nd oct 2023 -</span> by team ohmer
              </p>
            </div>
          </div>
          <div className="blogs-content" data-aos="fade-up">
            <div className="blog-img">
              <a href="#">
                <img src="images/blog1 Rent Reporting.jpg" alt="" className="img-fluid" />
              </a>
            </div>
            <div className="blog-cnt-title">
              <h2>
                <a href="#">rent collection and credit bureau reporting</a>
              </h2>
              <p>
                Report Rent Payments, Prevent
                <br /> Losses And Reward Good Tenants...
              </p>
              <p>
                <span>22nd oct 2023 -</span> by team ohmer
              </p>
            </div>
          </div>
          <div className="blogs-content blogs-content2" data-aos="fade-up">
            <div className="blog-img">
              <a href="#">
                <img src="images/blog1 monitor real estate.jpg" alt="" className="img-fluid" />
              </a>
            </div>
            <div className="blog-cnt-title">
              <h2>
                <a href="#">rent collection and credit bureau reporting</a>
              </h2>
              <p>
                Report Rent Payments, Prevent
                <br /> Losses And Reward Good Tenants...
              </p>
              <p>
                <span>22nd oct 2023 -</span> by team ohmer
              </p>
            </div>
          </div>
          <div className="blogs-content" data-aos="fade-up">
            <div className="blog-img">
              <a href="#">
                <img src="images/blog1 Rent Reporting.jpg" alt="" className="img-fluid" />
              </a>
            </div>
            <div className="blog-cnt-title">
              <h2>
                <a href="#">rent collection and credit bureau reporting</a>
              </h2>
              <p>
                Report Rent Payments, Prevent
                <br /> Losses And Reward Good Tenants...
              </p>
              <p>
                <span>22nd oct 2023 -</span> by team ohmer
              </p>
            </div>
          </div>
        </div>
        <div className="next-btn text-end">
          <a href="#">{'Next >>'}</a>
        </div>
      </div>
    </div>
  );
};

export default HeroArea;
