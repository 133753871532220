import React, {useEffect} from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import HeroArea from './/HeroArea';
import ReactGA from 'react-ga4';

const Invest = () => {
  useEffect(() => {
    ReactGA.send({hitType: 'pageview', page: '/invest'});
  }, []);

  return (
    <div>
      <Header>
        <HeroArea />
      </Header>
      <Footer />
    </div>
  );
};

export default Invest;
