import Config from '../config/Config';

class BaseApi {
  static getUrl(suburl) {
    return `${Config.API_URL}/api/` + suburl;
  }

  static getHeaders(contentType) {
    const headers = {
      Accept: 'application/json',
      'Content-Type': contentType,
      apiKey: Config.API_KEY,
    };
    return headers;
  }

  static getParams(method, suburl) {
    const params = {
      method: method,
      url: this.getUrl(suburl),
      headers: this.getHeaders('application/json'),
    };
    return params;
  }

  static getFileParams(method, suburl) {
    const params = {
      method: method,
      url: this.getUrl(suburl),
      headers: this.getHeaders('multipart/form-data'),
    };
    return params;
  }
}

export default BaseApi;
