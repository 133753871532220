import React from 'react';
import {Link, useLocation} from 'react-router-dom';

const Footer = () => {
  const location = useLocation();

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-inner">
          <div className="footer-nav">
            <div className="nav-items">
              <ul>
                <li>
                  <Link to="/" className={`${location.pathname === '/' && 'active-nav'}`}>
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/service-provider" className={`${location.pathname === '/service-provider' && 'active-nav'}`}>
                    service provider
                  </Link>
                </li>
                <li>
                  <Link to="/invest" className={`${location.pathname === '/invest' && 'active-nav'}`}>
                    invest
                  </Link>
                </li>
                {/* <li>
                  <Link to="/blogs" className={`${location.pathname === '/blogs' && 'active-nav'}`}>
                    blogs
                  </Link>
                </li> */}
              </ul>
            </div>
            <div className="footer-btm">
              <ul>
                <li>
                  <span>© 2024 Ohmer Canada Ltd.</span> All Rights Reserved
                </li>
                <li>
                  <a href="#"> / Privacy Policy / </a>
                </li>
                <li>
                  <a href="#"> Terms & Conditions </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
